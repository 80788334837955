/* styles.css */
.terms-of-service {
    padding: 20px;
  }
  
  .term-card {
    border: 1px solid #e8e8e8;
    padding: 16px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 768px) {
    .term-card {
      margin-bottom: 16px;
    }
  }
  