body {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure that the page takes up at least the full viewport height. */
  margin: 0;
}

.App {
  text-align: center;
}

.page-view {
  flex: 1; /* Grow to fill the available space between header and footer. 
  margin-top: 10vh;*/
}