body {
  margin: 0;
  font-style: normal;
  font-weight: 200;
  font-size: 0.9rem;
  font-family: "Roboto", "Montserrat", Helvetica, Arial, sans-serif !important;
  line-height: 20px;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.post {
  padding: 1%;
}

#post-page-content {
  width: 100%
}

.post-title-section {
  font-weight: 700;
  font-size: 20px;
}

.post-title {
  /*color: #212631;
  margin: 0 0 15px;*/
  font-weight: 400;
  text-decoration: none !important;
}

.post-title-wrap {
  width: 100%;
}

.category-badge, .last-update-badge {
  text-transform: uppercase;
  padding: 3px 10px;
  border-radius: 2px;
  margin-right: 15px;
  line-height: 1.42857143;
  font-family: 'Nunito',sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #3d455c;
}

.post-footer-section {
  background-color: #bfbf14;
}

.trending-posts {
  width: 100%;
  padding: 1em 3%;
}

#login-content {
  margin: 10em 2em;
}

#login-card {
  padding: 5%;
}

.post-card-body {
  padding: 0 2em;
}

.post-card {
  width: 60%;
}

.comment-card {
  margin: 3vh auto;
  width: 60%;
}

#post-body-text {
  padding: 3vh 0;
}

#comment-body-text {
  padding: 1% 0;
}

.post-footer-area {
  margin: 0;
}

.comments-footer-area {
  margin: 0;
}

img {
  width: 100% !important;
}

.comment-action {
  padding: 1% 10%;
}

.text-cont {
  padding-top: 2%;
}

.encloser {
  border: 1px solid black;
  border-radius: 25px;
  margin: 3% 10%;
  padding: 3% 5%;
}

.encloseEditor {
  border: 1px solid black;
  border-radius: 25px;
  /*margin: 3% 10%;*/
  padding: 3% 5%;
}

.editorAlignment {
  text-align: left;
}

#avatar {
  width: 30px !important;
  height: 30px !important;
}

#home-jumbo {
  background-color: '#1b1c1d';
  height: 100vh;
  background: transparent url('http://assets.iceable.com/img/noise-transparent.png') repeat 0 0;
  background-repeat: repeat;
  opacity: .9;
  visibility: visible;
  padding: 20% 10% 10%;
}

@keyframes bg-animation {
    0% { transform: translate(0,0) }
    10% { transform: translate(-5%,-5%) }
    20% { transform: translate(-10%,5%) }
    30% { transform: translate(5%,-10%) }
    40% { transform: translate(-5%,15%) }
    50% { transform: translate(-10%,5%) }
    60% { transform: translate(15%,0) }
    70% { transform: translate(0,10%) }
    80% { transform: translate(-15%,0) }
    90% { transform: translate(10%,5%) }
    100% { transform: translate(5%,0) }
}

@media screen and (min-width: 1440px) {
  .post-card {
    width: 40%;
  }

  .comment-card {
    width: 40%;
  }
}


@media screen and (max-width: 768px) {
  .sidebar_el_mobile {
    display: none;
  }

  .post-card {
    width: 90%;
  }

  .comment-card {
    width: 90%;
  }

  /*.navbar-brand {
    max-width: 50%;
  }

  #profile-follow {
    position: 'absolute';
    top: '50%' !important;
    left: '70%' !important;
  }*/

  .follow-badge {
    padding: 30% !important;
  }

  .trending-posts {
    width: inherit;
  }
}