/* styles.css */
.about-us {
    padding: 20px;
}

.mission-card, .vision-card {
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .mission-card, .vision-card {
        margin-bottom: 10px;
    }
}
  