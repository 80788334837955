/* styles.css */
.team-members {
    padding: 20px;
}

.member-summary {
    margin-top: 12px;
}

@media (max-width: 576px) {
    .member-summary {
        font-size: 14px;
    }
}
  