/* For WebKit browsers (Chrome, Safari) */
.element-with-scrollbar::-webkit-scrollbar {
    width: 12px; /* Set the width of the scrollbar */
}

.element-with-scrollbar::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Set the color of the scrollbar track */
}

.element-with-scrollbar::-webkit-scrollbar-thumb {
    background-color: #1b1c1d; /* Set the color of the scrollbar thumb */
    border-radius: 6px; /* Set the border radius of the thumb */
}

.element-with-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Set the color of the thumb on hover */
}

.element-with-scrollbar::-webkit-scrollbar-button {
    display: none; /* Hide the directional buttons */
}

.element-with-scrollbar::-webkit-scrollbar-corner {
    background-color: #f1f1f1; /* Set the color of the scrollbar corner */
}

/* For Firefox */
.element-with-scrollbar {
    scrollbar-color: #1b1c1d #f1f1f1; /* thumb color, track color */
    scrollbar-width: thin; /* Set the width of the scrollbar */
}

.element-with-scrollbar:hover {
    scrollbar-color: #555 #f1f1f1; /* thumb color on hover, track color */
}
